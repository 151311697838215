import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Navbars from './components/navbars/Navbars';
import Footers from './components/footers/Footers';
import Backup from './pages/Backup/Backup';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function openWhatsUp() {
  window.open('https://api.whatsapp.com/send/?phone=%2B966594753712&text=+%D8%A7%D9%84%D8%B3%D9%84%D8%A7%D9%85+%D8%B9%D9%84%D9%8A%D9%83%D9%85&type=phone_number&app_absent=0', '_blank');
}


function App() {
  return (
    <div className="App">
      <Router>
        <Navbars />
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/Backup" element={<Backup />} />
        </Routes>

        <Footers />

        <Fab variant="extended" size="large" sx={{ position: 'fixed', bottom: 16, right: 16,fontFamily: 'F29LT Riwaya Italic',fontSize: 18,backgroundColor: "#22b148",color: "#fff" }}
        onClick={() => openWhatsUp()}>
        <WhatsAppIcon sx={{ mr: 1 }} />
          واتساب
        </Fab>
      </Router>
    </div>
  );
}

export default App;
