import React from 'react'
import { Container } from 'react-bootstrap'

function Privicey() {
  return (
    <div dir='rtl' className='  bg-light py-5 pb-4 mt-5'>
    
    <Container>
    <Container >
    <div className=' row p-2'>
    <h3 className=' fw-bolder p-3'>السياسة والخصوصية</h3>
    
    <p className='mb-0'>
    توفر فيجن منصة عبر الإنترنت ومتنقلة لتوصيل الشحنات عن طريق مزودي الخدمات .  يمكن للشاحنين تقديم طلب لنقل البضائع (&quot;الشحن&quot;)، ويمكن لشركات النقل قبول هذه الطلبات وتعيين السائقين للوفاء بها، ويمكن للشاحنين والناقلين والسائقين تتبع هذه الطلبات. توضح سياسة الخصوصية هذه كيفية قيام فيجن (&quot;نحن&quot;) بجمعالمعلومات واستخدامها والكشف عنها عند استخدامك لمواقعنا الإلكترونية وتطبيقات الهاتف المحمول والمنتجات والخدمات الأخرى عبر الإنترنت (بشكل جماعي أو &quot;الخدمات&quot;) أو عندما تتفاعل بطريقة أخرى معنا. يجوز لنا تغيير سياسة الخصوصية هذه من وقت لآخر. في حالة إجراء تغييرات، سنقوم بإعلامك عن طريق مراجعة التاريخ الموجود أعلى السياسة، وفي بعض الحالات، قد نوفر لك إشعاًرا إضافياً (مثل إضافة بيان إلى صفحتناالرئيسية أو إرسال إشعار إليك). نحن نشجعك على مراجعة سياسة الخصوصية عند الوصول إلى الخدمات أوالتفاعل معنا بطريقة أخرى لتبقى على اطلاع حول ممارساتنا المعلوماتية والخيارات المتاحة لك .تم آعداد هذه الشروط باللغة العربية. وتم ترجمتها الى اللغة الإنجليزية لمستخدمي اللغة الإنجليزية. تعتبر اللغة
    </p>
    </div>
    </Container>
    
    </Container>
    
    
    
    </div>
  )
}

export default Privicey