import React from 'react';
import { Bounce, Fade, Zoom } from 'react-reveal';

function openGooglePlay() {
  window.open('https://play.google.com/store/apps/details?id=com.logestechs.customer_vision', '_blank');
}

function openAppStore() {
  window.open('https://apps.apple.com/us/app/%D9%81%D9%8A%D8%AC%D9%86-%D9%84%D9%84%D8%B4%D8%AD%D9%86-%D9%88%D8%A7%D9%84%D8%AA%D9%88%D8%B5%D9%8A%D9%84/id6541759722', '_blank');
}

function openWhatsUp() {
  window.open('https://api.whatsapp.com/send/?phone=%2B966594753712&text=+%D8%A7%D9%84%D8%B3%D9%84%D8%A7%D9%85+%D8%B9%D9%84%D9%8A%D9%83%D9%85&type=phone_number&app_absent=0', '_blank');
}

function openRegistrationPage() {
  // window.open('https://super.vision-log.com/register/', '_vision');
}

function openLoginPage() {
  // window.open('https://portal.vision-log.com/#/login', '_vision');
  window.open('https://vision.logestechs.com/', '_vision');

}


const buttonStyles = {
  padding: '10px 20px',
  margin: '5px',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: 'bold',
  borderRadius: '18px',
  border: 'none',
};

const registerButtonStyles = {
  ...buttonStyles,
  backgroundColor: '#A034B4',
  color: '#fff',
};

const loginButtonStyles = {
  ...buttonStyles,
  backgroundColor: '#2ecc71',
  color: '#fff',
};


function HomeBanner() {
  return (
    <div dir='rtl'>
      <div className='container py-3'>
        <div className='row justify-content-right align-items-center'>
          <div className='col-md-8'>
            <div className='container'>
              <Zoom right>
                <span className='p-2'>اسرع شحن في السعودية</span>
                <h2 className='p-2'>فيجن للخدمات اللوجستية</h2>
              </Zoom>
              <Fade right>
                <p className='p-2'>
                  ستستفيد من أفضل الحلول اللوجستية مع فيجن. نحن نجعل اللوجستيات تبدو سهلة، حيث نقدم خدمات متكاملة تلبي احتياجات عملك بكفاءة واحترافية. اكتشف حلاً مخصصًا للنقل والتخزين وإدارة السلسلة اللوجستية الخاصة بك، مع توفير موثوقية عالية وتكلفة تنافسية. انضم إلى عملائنا المميزين واستمتع بتجربة لوجستية فائقة الجودة مع فيجن اليوم."
                </p>
              </Fade>
            </div>
            <div className='button-group p-2'>
              <div className='d-flex w-auto justify-content-center'>
                <Bounce right>
                  <div className='col'>
                    <button className='apple_btn' onClick={openAppStore}></button>
                    <button className='Goagle_btn' onClick={openGooglePlay}></button>
                  </div>
                </Bounce>
              </div>
            </div>
            <div className='button-group p-2 d-flex justify-content-center mt-2'>
              {/* <button className='register_btn' style={registerButtonStyles} onClick={openRegistrationPage}>تسجيل مستخدم جديد</button> */}
              <button className='login_btn' style={loginButtonStyles} onClick={openLoginPage}>التسجيل و الدخول</button>
            </div>
         
          </div>

          <div className='col-md-4'>
            <Fade></Fade>
            <div className='d-flex justify-content-center align-items-center container img-landing'>
              <Fade bottom >
                <img src={require('../../assets/Group 1000006479.png')} alt='Landing' />
              </Fade>
            </div>
          </div>
        </div>
        {/* إضافة زر لتسجيل مستخدم جديد */}
       
        {/* <Zoom>
        <button className='whatsup'  onClick={openWhatsUp}></button>
        </Zoom> */}
      </div>
    </div>
  );
}

export default HomeBanner;
