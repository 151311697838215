import React from 'react'
import { Container} from 'react-bootstrap'
import { Zoom , Fade} from 'react-reveal'
import Leisense from '../../components/Leisense/Leisense'
import Privicey from '../../components/Privicey/Privicey'

function Backup() {
  return (
    <div>
    <Leisense/>
    <Privicey/>
    
    </div>
  )
}

export default Backup