import React from 'react';
import './Home.css';
import HomeBanner from '../../components/HomeBanner/HomeBanner';
import HomeService from '../../components/HomeService/HomeService';
import HomeOurVision from '../../components/HomeOurVision/HomeOurVision';
import HomePayment from '../../components/HomePayment/HomePayment';
import HomePartner from '../../components/HomePartner/HomePartner';

function openGooglePlay() {
  window.open('https://play.google.com/store/apps/details?id=com.visionlog.app', '_blank');
}

function openAppStore() {
  window.open('https://apps.apple.com/sa/app/%D8%A8%D9%88%D8%A7%D8%A8%D8%A9-%D9%81%D9%8A%D8%AC%D9%86/id6446868223', '_blank');
}

function openWhatsUp() {
  window.open('https://api.whatsapp.com/send/?phone=%2B966594753712&text=+%D8%A7%D9%84%D8%B3%D9%84%D8%A7%D9%85+%D8%B9%D9%84%D9%8A%D9%83%D9%85&type=phone_number&app_absent=0', '_blank');
}

function Home() {
  return (
    <div dir='rtl'>
      <HomeBanner/>
      <HomeService/>
      <HomeOurVision/>
      <HomePayment/>
      <HomePartner/>
    </div>
  );
}

export default Home;